<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useToken } from "@/composable/useToken";
import { useApi } from "@/composable/useApi";
import { RouteNames } from "@/types";
import GreyLayout from "@/layouts/GreyLayout.vue";
import router from "@/router";

const { t } = useI18n();
const api = useApi();
const token = useToken();

const existingclient = ref();
const apiError = ref("");

async function submitConsent() {
  try {
    await api.submitVelo({
      token: token.value,
      ...existingclient.value,
      optin_transilien_train_velo: true,
    });

    router.push({ name: RouteNames.VeloSuccess });
  }
  catch (error: unknown | Error) {
    apiError.value
      = error instanceof Error ? error.message : JSON.stringify(error);
  }
}

watch(
  token,
  async (tok?: string) => {
    if (tok) {
      try {
        existingclient.value = await api.getClientB2c(tok);
      }
      catch (error) {
        // Client does not exist
        document.location.href = "https://www.transilien.com/";
      }
    }
    else {
      // Token is missing
      document.location.href = "https://www.transilien.com/";
    }
  },
  { immediate: true },
);
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/banner-b2c.png"
      width="1024"
      height="178"
      :alt="t('b2c-img-alt')"
      class="mb-10 w-full"
    >

    <h1 class="mb-6 text-xl text-[#055754] md:text-center md:text-2xl">
      {{ t("landing-velo-title") }}
    </h1>

    <div class="flex justify-center">
      <button
        type="submit"
        :button-attributes="{ type: 'submit' }"
        class="mx-auto mb-2 md:max-w-[402px] md:text-lg"
        @click="submitConsent()"
      >
        {{ t("landing-velo-cta") }}
      </button>
      <p v-if="apiError" class="mt-2 text-center text-sm text-red-500">
        {{ apiError }}
      </p>
    </div>
  </GreyLayout>
</template>

<style scoped lang="scss">
  button {
    @apply bg-[#055754] text-white mx-auto mb-2 md:text-lg;
    min-height: 46px;
    width: 402px;
  }
</style>
